/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from "components/Button";
import Label from "components/Label";
import Media from "components/Media";
import { useRouter } from "next/router";
import React, {
  FC,
  ElementType,
  useState,
  useEffect,
  useCallback
} from "react";
import { FiMenu as OpenNavigation } from "react-icons/fi";
import { motion } from "framer-motion";

import useScreenSize from "hooks/use-screen-size";
import { useSetRecoilState } from "recoil";
import useScroll from "hooks/use-scroll";
import useComponentDimensions from "hooks/use-component-dimensions";
import Option from "./components/Option";

import styles from "./styles.module.scss";
import { CTA, NavBarOption } from "./types";
import { menuStateAtom } from "./atoms";

interface Props {
  options: NavBarOption[];
  Logo: ElementType;
  cta?: CTA;
  linkAnnouncement: string;
  announcement?: string;
}

const NavBar: FC<Props> = ({
  options,
  announcement,
  Logo,
  cta,
  linkAnnouncement
}) => {
  const [hasScrolled, setHasScrolled] = useState(true);
  const { isResponsive } = useScreenSize();
  const setSelectedOption = useSetRecoilState(menuStateAtom);

  const [openNavigation, setOpenNavigation] = useState(!isResponsive);

  useEffect(() => {
    setOpenNavigation(!isResponsive);
  }, [isResponsive]);

  const { measure: measureNav, height: navHeight } = useComponentDimensions();

  const onScroll = useCallback(() => {
    setHasScrolled(false);

    if (isResponsive) setOpenNavigation(false);
  }, [isResponsive]);

  const { up } = useScroll(onScroll);

  useEffect(() => {
    if (!openNavigation) setSelectedOption(null);
  }, [openNavigation]);

  const router = useRouter();

  const { pathname } = router;

  return (
    <>
      {announcement && (
        <div
          onClick={() => window.open(linkAnnouncement, "_blank")}
          className={styles.announcement}
        >
          <Label variant="centered" color="white">
            {announcement}
          </Label>
        </div>
      )}
      <motion.div
        initial={{ position: "sticky", top: -500 }}
        animate={
          up || hasScrolled
            ? {
                position: "sticky",
                top: 0
              }
            : {
                position: "sticky",
                top: -(navHeight + 15)
              }
        }
        className={styles.container}
      >
        <div ref={measureNav} className={styles.wrapper}>
          <div className={styles.navbar}>
            <div className={styles.navbarHeader}>
              <Logo onClick={() => router.push("/")} className={styles.logo} />
              <Media at={["responsive"]}>
                <Button
                  onClick={() => setOpenNavigation(!openNavigation)}
                  variant="text"
                  Icon={<OpenNavigation className={styles.openNavigation} />}
                />
              </Media>
            </div>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={
                openNavigation
                  ? {
                      height: "auto",
                      opacity: 1
                    }
                  : { height: 0, opacity: 0 }
              }
              className={styles.section}
            >
              <div className={styles.options}>
                {options.map((option) => (
                  <Option pathname={pathname} key={option.name} {...option} />
                ))}
              </div>
              {cta && (
                <Button
                  className={styles.cta}
                  onClick={() => {
                    router.push(cta.to);
                  }}
                  variant="cta"
                >
                  {cta.reads}
                </Button>
              )}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

NavBar.defaultProps = {
  cta: undefined,
  announcement: undefined
};

export default NavBar;
