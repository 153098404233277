/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Label from "components/Label";
import Link from "components/Link";
import { NavBarOption } from "components/NavBar/types";
import React, { FC } from "react";
import { useRecoilState } from "recoil";
import { FiChevronDown as Chevron } from "react-icons/fi";

import { AnimatePresence, motion } from "framer-motion";
import useScreenSize from "hooks/use-screen-size";
import styles from "./styles.module.scss";
import { menuStateAtom } from "../../atoms";

const Option: FC<NavBarOption & { pathname: string }> = ({
  to,
  name,
  pathname
}) => {
  const [selectedOption, setSelectedOption] = useRecoilState(menuStateAtom);
  const { isResponsive } = useScreenSize();

  const isMenu = Array.isArray(to);

  const selected = isMenu
    ? to.some(({ to: optionTo }) => pathname === optionTo)
    : pathname === to;

  const selectionHandler = () => {
    if (selectedOption === name) setSelectedOption(null);
    else setSelectedOption(name);
  };

  return isMenu ? (
    <div
      {...(isResponsive
        ? { onClick: selectionHandler }
        : { onMouseLeave: selectionHandler, onMouseEnter: selectionHandler })}
      className={styles.menu}
    >
      <div className={`${styles.header} ${selected && styles.selectedOption}`}>
        <Label breakword={false} color="supplemental" variant="capitalized-s">
          {name}
        </Label>
        <Chevron className={styles.chevron} />
      </div>
      <AnimatePresence>
        {selectedOption === name && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className={styles.dropdown}
          >
            {to.map(({ to: optionTo, name: optionName }) => {
              const optionSelected = pathname === optionTo;

              return (
                <Link
                  className={`${styles.menuOption} ${
                    optionSelected && styles.selectedOption
                  }`}
                  label={optionName}
                  to={optionTo}
                />
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  ) : (
    <Link
      className={selected ? styles.selectedOption : undefined}
      label={name}
      to={to}
    />
  );
};

export default Option;
