import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

const useScroll = (callback?: (e: Event) => void) => {
  const [scroll, setScroll] = useState({
    scroll: 0,
    up: false,
    down: false,
    scrolling: false
  });

  const updateScrolling = useCallback(
    debounce(() => setScroll((state) => ({ ...state, scrolling: false })), 200),
    []
  );

  useEffect(() => {
    const onScroll = (event: Event) => {
      const { target } = event;
      const { scrollTop: scrolled } = target as Element;

      setScroll((state) => ({
        scroll: scrolled,
        up: state.scroll - scrolled > 0,
        down: state.scroll - scrolled < 0,
        scrolling: true
      }));

      updateScrolling();

      callback?.(event);
    };

    const app = document.getElementById("app");

    app?.addEventListener("scroll", onScroll);

    return () => app?.removeEventListener("scroll", onScroll);
  }, [callback]);

  return scroll;
};

export default useScroll;
